<template>
  <div>
    <!-- <span > Result Value: {{ qrReaderResult }}</span> -->
    <StreamBarcodeReader
      v-if="!qrReaderResult"
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>
  </div>
</template>
<script>
import QRCode from 'qrcode';
import { StreamBarcodeReader } from 'vue-barcode-reader';
export default {
  name: 'templates-formComponentsExecute-QR-READER',
  components: { StreamBarcodeReader },
  props: [
    'isFromDocument',
    'isFromTemplates',
    'data',
    'form',
    'isView',
    'fromRepeatable',
    'checkIsDisabled',

    'isFromRelations',
    'allowMultiple',
    'relationResultField',
    "isFromEntityDataTable"
  ],
  mounted() {},
  data() {
    return {
      qrLoading: false,
      qrCode: null,
      qrReaderResult: '',
    };
  },

  methods: {
    generateQRCode() {
      this.qrLoading = true;
      if (
        this.data.validations.qr_data_field &&
        this.form &&
        this.form[this.data.validations.qr_data_field]
      ) {
        QRCode.toDataURL(
          this.form[this.data.validations.qr_data_field],
          { errorCorrectionLevel: 'H' },
          (err, url) => {
            if (err) throw err;
            this.qrCode = url;
          }
        );
        setTimeout(() => {
          this.qrLoading = false;
        }, 2000);
      } else {
        this.qrCode = null;
        setTimeout(() => {
          this.qrLoading = false;
        }, 2000);
      }
    },
    getValidUrl(url) {
      if (!url.includes('http://') && !url.includes('https://'))
        return 'https://' + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    onDecode(text) {
      if(this.isFromEntityDataTable){
        this.$emit('code-scanned', text);
        return
      }
      this.qrReaderResult = text;
      if (this.isFromTemplates) {
        this.$set(this.form, this.data.key, text);
        this.$emit('before-close');
      }

      if (this.isFromRelations) {
        if (!this.relationResultField.includes(text)) {
          this.relationResultField.push(text);
        }
        this.$emit('before-close');
      }


      // this.visible = false;
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
  },
  watch: {
    form: {
      handler(updatedData) {
        this.$nextTick(() => {
          console.log('Updated Scan Data', updatedData);
          // if(form[data.key])
          // {

          // }
          // this.generateQRCode(updatedData);
        }, 1000);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sle {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  &:hover {
    border-color: #409eff;
    /* Change the border color on hover */
  }

  &:focus {
    border-color: #409eff;
    /* Change the border color when focused */
  }

  &::placeholder {
    color: #ccc;
    /* Use your desired lighter gray color here */
  }
  @media only screen and (max-width: 767px) {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: 16px !important;
    height: 32px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
}

.disabled-field {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  /* Set your preferred greyed-out color */
  pointer-events: painted;
  cursor: not-allowed;
}
</style>
